import { Component, ElementRef, OnInit, TemplateRef } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { StorageType } from 'src/app/common/enum/storage.enum';
import { HeaderStatusService } from 'src/app/common/services/header-status.service';
import { StorageService } from 'src/app/common/services/storage.service';
// import { ValidationConstant } from 'src/app/common/validation/validation.constant';
// import { Validator } from 'src/app/common/validation/validator';
// import { PaymentInformationService } from '../../services/payment-information.service';
import { SendEmailComponent } from '../send-email/send-email.component';
import { TransactionStatusMapEnum } from 'src/app/common/enum/transaction-status-map.enum';
import { AdditionalFieldsMapEnum } from 'src/app/common/enum/additional-fields-map.enum';
import { CommonService } from 'src/app/common/services/common.service';
import { PaymentSummaryService } from '../../services/payment-summary.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Exception } from 'src/app/common/exceptions/exception';
import { ToasterService } from 'src/app/common/services/toaster.service';


@Component({
  selector: 'app-payment-summary',
  templateUrl: './payment-summary.component.html',
  styleUrls: ['./payment-summary.component.scss'],
})
export class PaymentSummaryComponent implements OnInit {
  bsModalRef: BsModalRef;
  paymentSummary: any = {};
  AdditionalData: any;
  transactionSummary: any;
  agentSettingsData: any;
  loader: boolean = true;
  modalRef: BsModalRef;
  additionalFieldsData:any=[];
  fullAddress: any = {};
  ISDCode: any;
  merchantISDCode: any;
  merchantId: any;
  transactionId: any;
  shopperTemplate: SafeHtml;
  public receiptPrintConfig = {
    printMode: 'template-popup',
    popupProperties:
      'toolbar=yes,scrollbars=yes,resizable=yes,top=0,left=0,fullscreen=yes',
    pageTitle: 'Receipt',
    styles: [
      `@media screen, print {
      body {
        -webkit-print-color-adjust: exact;
        color-adjust: exact !important;
        height: auto;
        margin: 0 !important;
        padding: 0 !important;
        overflow: auto;
        background:#EFF1F6 !important
      }
        #merchantReceipt, #shopperReceipt{
          margin: 0 auto;
        }
        .tooltip-block{
          display:none !important;
        }
        #merchantReceipt .close-button,#shopperReceipt .close-button{
          display:none;
        }
      }

      `,
    ],
  };
  constructor(
    private modalService: BsModalService,
    private headerService: HeaderStatusService,
    private commonService: CommonService,
    private storageService: StorageService,
    private paymentSummarys: PaymentSummaryService,
    private sanitizer: DomSanitizer,
    private el: ElementRef,
    private toasterService: ToasterService
  ) {}
  public printConfig = {
    printMode: 'template-popup',
    popupProperties:
      'toolbar=yes,scrollbars=yes,resizable=yes,top=0,left=0,fullscreen=yes',
    pageTitle: 'Transaction Receipt',
    styles: [
      `@media print {
      body {-webkit-print-color-adjust: exact; color-adjust: exact !important;height: auto;
        height:90vh;
        margin: 0 !important;
        padding: 0 !important;
        overflow: hidden; background:white !important}
      }

      `,
    ],
  };
  ngOnInit(): void {
    let sessiontransactionSummary = this.storageService.get(
      StorageType.session,
      'transactionSummary'
    );
    // this.transactionSummary = JSON.parse(sessiontransactionSummary || '{ }');
    this.transactionSummary = sessiontransactionSummary ? JSON.parse(sessiontransactionSummary) : {};

    let agentSettingsData = this.storageService.get(
      StorageType.session,
      'agentSettings'
    );
    // this.agentSettingsData = JSON.parse(agentSettingsData || '{ }');
    this.agentSettingsData = agentSettingsData ? JSON.parse(agentSettingsData) : {};
    this.merchantId = this.agentSettingsData?.merchantId
    this.merchantISDCode = this.agentSettingsData?.transactionSummary?.isdCode
    if(this.merchantISDCode){
    if(this.merchantISDCode.startsWith('00')){
      this.agentSettingsData.transactionSummary.isdCode = `+${this.merchantISDCode.substring(2)}`;
    }
    else{
      this.agentSettingsData.transactionSummary.isdCode = this.merchantISDCode
    }
  }
    // this.fullAddress = this.commonService.getFullAddress(JSON.parse(JSON.stringify(this.agentSettingsData?.merchantDetail?.address)), []);
    this.fullAddress = this.agentSettingsData?.merchantDetail?.address ? 
    this.commonService.getFullAddress(JSON.parse(JSON.stringify(this.agentSettingsData?.merchantDetail?.address)),[]) : null;
    this.headerService.castHeaderStatus.subscribe((data) => {
      let serviceData = data;
      this.paymentSummary = serviceData['paymentSummary'];
      this.transactionId = this.paymentSummary?.transactionId;
      this.ISDCode = this.paymentSummary?.billingContact?.isdCode
      if(this.ISDCode){ 
      if(this.ISDCode.startsWith('00')){
        this.paymentSummary.billingContact.isdCode = `+${this.ISDCode.substring(2)}`;
      }
      else{
        this.paymentSummary.billingContact.isdCode = this.ISDCode
      }
    }
      this.AdditionalData = JSON.parse(this.paymentSummary.additionalFields);
      if (this.paymentSummary?.additionalFields != null) {
        var additionalDetails = JSON.parse(this.paymentSummary?.additionalFields) || {};
        Object.keys(additionalDetails).every(key => {
          if (key == 'shoppercompanyname') {
            return false;
          }
          
          if (additionalDetails[key] != "" && additionalDetails[key] != null) {
            this.paymentSummary.referenceValue = additionalDetails[key];
            this.paymentSummary.referenceLable = AdditionalFieldsMapEnum[key];
            let obj={
              referenceValue:additionalDetails[key],
              referenceLable : AdditionalFieldsMapEnum[key]
            }
              this.additionalFieldsData.push(obj);
          }
          return true;
        });    
      }
    });
    this.loader = false;
  }

  // open send email modal

  showModalPopup(template: TemplateRef<any>) {
    const config = {
      backdrop: false,
      ignoreBackdropClick: true,
      class: 'modal-lg modal-dialog-centered receiptEmail',
    };
    this.bsModalRef = this.modalService.show(SendEmailComponent, config);
  }


closeModel() {
  this.modalRef?.hide();
}

private insertCustomContentForShopperReceipt(html: string): string {
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, 'text/html');

  const headerDiv = doc.querySelector('.header');
  if (headerDiv) {
    headerDiv.classList.add('email-body-section');
  }

  const targetDiv = doc.querySelector('.transaction-status');

  if (targetDiv) {
    const customHtml = `
      <div class="print-none">
        <a role="button" class="print-icon" style="color: white;" id="printReceipt-shopper" ad-tooltip
          data-body="Print receipt">
          <svg width="19" height="16" viewBox="0 0 19 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M5 1a2 2 0 0 0-2 2v1h10V3a2 2 0 0 0-2-2H5zm6 8H5a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1z" fill="inherit" />
            <path
              d="M0 7a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2h-1v-2a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v2H2a2 2 0 0 1-2-2V7zm2.5 1a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z"
              fill="inherit" />
          </svg>
        </a>
        <a role="button" class="email-icon" style="color: white;" id="emailReceipt-shopper" ad-tooltip
          data-body="Email receipt" data-bs-toggle="modal" data-bs-target="#email-send">
          <svg width="17" height="13" viewBox="0 0 17 13" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
            <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555ZM0 4.697v7.104l5.803-3.558L0 4.697ZM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586l-1.239-.757Zm3.436-.586L16 11.801V4.697l-5.803 3.546Z"></path>
          </svg>
        </a>
      </div>
    `;

    targetDiv.insertAdjacentHTML('afterend', customHtml);
  }

  setTimeout(() => {
    this.addEventListenersShopper();
  }, 0);

  return doc.documentElement.outerHTML;
}

private addEventListenersShopper(): void {
  const printButton = this.el.nativeElement.querySelector('#printReceipt-shopper');
  const emailButton = this.el.nativeElement.querySelector('#emailReceipt-shopper');
  if (printButton) {
    printButton.addEventListener('click', () => this.printReceiptShopper());
  }

  if (emailButton) {
    emailButton.addEventListener('click', () => this.openEmailModal());
  }

}

private printReceiptShopper(): void {
  const printContents = document.getElementById('shopperReceipt')?.innerHTML;
  if (printContents) {
    const popupWin = window.open('', '_blank', this.receiptPrintConfig.popupProperties);
    if (popupWin) {
      popupWin.document.open();
      popupWin.document.write(`
        <html>
          <head>
            <title>${this.receiptPrintConfig.pageTitle}</title>
            <style>
              ${this.receiptPrintConfig.styles.join(' ')}
            </style>
          </head>
          <body onload="window.print();window.close()">
            ${printContents}
          </body>
        </html>
      `);
      popupWin.document.close();
    }
  }
}

  openModal(template: TemplateRef<any>){
    this.loader = true;
    let reqObj ={
      'userType' : 'Customer',
      'MerchantToken' : this.agentSettingsData.token
    }
    this.paymentSummarys.getTemplatePreview(this.merchantId, this.transactionId, reqObj).subscribe(((response:any) => {
        this.loader = false;
        const manipulatedHtml = this.insertCustomContentForShopperReceipt(response.templateWithMappedTagData);
        this.shopperTemplate = this.sanitizer.bypassSecurityTrustHtml(manipulatedHtml);
        this.openShopperPopup();

      }),
      error => {
        this.loader = false;
        const toastMessage = Exception.exceptionMessage(error);
        this.toasterService.showError(toastMessage.join(', '), 'full');
      }
    );
  }
  openEmailModal() {
    this.closeShopperPopup();
    const config = {
      backdrop: false,
      ignoreBackdropClick: true,
      class: 'modal-lg modal-dialog-centered receiptEmail',
    };
    this.bsModalRef = this.modalService.show(SendEmailComponent, config);
  }
  closeEmailModal() {
    let refundModalRef = document.getElementById('emailReceiptModal');
    refundModalRef.classList.remove('show');
    refundModalRef.style.display = 'none';
    refundModalRef.classList.remove('popup-background');
  }


  openShopperPopup() {
    var redPopup = document.querySelector(".faccesse.ticket_red") as HTMLElement;
    redPopup.style.display = "block";
    document.body.classList.remove("sideNavBody");
    document.querySelector(".ticket_red").classList.add('popup-background');
  }


  closeShopperPopup() {
    var redPopup = document.querySelector(".faccesse.ticket_red") as HTMLElement;
    redPopup.style.display = "none";
    document.body.classList.add("sideNavBody");
    document.querySelector(".ticket_red").classList.remove('popup-background');
  }

}
