import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root',
})
export class ToasterService {
  data: any;
  toastConfig1 = {
    positionClass: 'toast-bottom-left',
    timeOut: 5000,
    closeButton: true,
  };

  toastConfig2 = {
    positionClass: 'toast-top-full-width',
    closeButton: true,
    timeOut: 5000,
  };
  constructor(private toastr: ToastrService) {}

  showSuccess(message, value) {
    let config = value == 'full' ? this.toastConfig2 : this.toastConfig1;
    this.toastr.success(message, null, config);
  }

  showError(message, value) {
    let config = value == 'full' ? this.toastConfig2 : this.toastConfig1;
    this.toastr.error(message, null, config);
  }

  showInfo(message, value) {
    this.toastr.info(message, null, value);
  }

  showWarning(message, value) {
    this.toastr.warning(message, null, value);
  }
}
