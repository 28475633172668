import { Injectable } from '@angular/core';
import { catchError, tap } from 'rxjs';
import { AppSetting } from 'src/app/common/constants/appsetting.constant';
import { CommonApiFuncService } from 'src/app/common/services/common-api-func.service';
import { CommonService } from 'src/app/common/services/common.service';

@Injectable({
  providedIn: 'root'
})
export class PaymentSummaryService {

  constructor(
        private commonAPIFuncService: CommonApiFuncService,
        private commonService: CommonService,
  ) { }
  getTemplatePreview(merchantId, transactionId, reqObj, userType?, ) {
      let url = AppSetting.paymentInformation.templatePreview.replace('{merchantId}', merchantId).
      replace('{transactionId}', transactionId);
      if(userType){
        url = `${url}${this.commonService.buildQuery(userType)}`;
      }
      return this.commonAPIFuncService.post(url, reqObj).pipe(
        tap((_a) => this.commonService.log(`fetched`)),
        catchError(this.commonService.handleError('', [])),
      );
    }
}
